import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { spacings } from '@sport1/news-styleguide/spacings'
import { isPureActive } from '../ThirdParty/Piano/util'
import { useConsentManager } from '@/utils/consent/ConsentInfoProvider'
import { CUSTOM_VENDOR_IDS } from '@/utils/consent/consentVendors'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

type ApesterProps = {
    token: string
    height: number
    title?: string
}

const Apester: FC<ApesterProps> = ({ token, title }) => {
    const { consentInfo, getConsentById } = useConsentManager()
    //TODO: AD_KEYS- check if this is needed
    const { adKeys = [] } = useAdPlacementContext()

    if (isPureActive()) return null

    if (consentInfo.consent_info === 'off') {
        return <NonFlexingContainer className="apester-media-fallback" marginBottom="spacing-9" />
    }

    return getConsentById('custom', CUSTOM_VENDOR_IDS.APESTER) ? (
        <NonFlexingContainer
            marginBottom={spacings['spacing-11']}
            className="px-3 sm:px-6 md:px-3 lg:px-0"
        >
            <CardHeadline
                cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                text={title || ''}
                testID="apester-headline"
            />
            <div
                data-testid="apester-media"
                className="apester-media"
                data-token={token}
                data-context="true"
                data-tags={adKeys.map(key => key.replaceAll(' ', '-')).join(',')}
                data-fallback="true"
            />
        </NonFlexingContainer>
    ) : null
}

export default Apester
